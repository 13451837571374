import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import { useImporter } from 'src/contexts/ImporterContext'
import ToggleSwitch from './ToggleSwitch'

const AppTenantDropdown = () => {
  const { importer, importers, selectedImporterId, setSelectedImporterId } = useImporter()

  // useEffect(() => {
  //   setSelectedImporter(selectedImporterId)
  // }, [selectedImporterId])

  const handleSelectChange = (newSelectedId) => {
    setSelectedImporterId(newSelectedId)
    //window.location.reload()
  }
  return importers?.length > 1 ? (
    <>
      <CDropdown
        defaultValue={selectedImporterId}
        variant="nav-item"
        className="fw-semibold pe-2"
        popper={true}
      >
        <CDropdownToggle color="light" shape="rounded-pill">
          {importer?.name}
        </CDropdownToggle>
        <CDropdownMenu>
          {importers?.map((importer) => (
            <CDropdownItem
              key={importer.tenantId}
              active={importer.tenantId == selectedImporterId}
              onClick={() => handleSelectChange(importer.tenantId)}
            >
              {importer.name}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    </>
  ) : (
    <div className="fw-semibold pe-2">{importer?.name}</div>
  )
}

export default AppTenantDropdown

// AppTenantDropdown.propTypes = {
//   importerIds: propTypes.array,
//   selectedImporterId: propTypes.string,
//   onImporterSelect: propTypes.func,
// }
