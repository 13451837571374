// src/App.js
import './scss/style.scss'
import React, { Component, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import PropTypes from 'prop-types'
import { slk } from 'survey-core'
import { ImporterProvider, useImporter } from './contexts/ImporterContext'
import { CreditsProvider } from './contexts/CreditsContext'
import DefaultLayout from './layout/DefaultLayout'
import LoginHandler from './LoginHandler'
import { useAuthState } from './hooks/useAuthState'
import CertificateTemplate from './views/consignments/contents/generate/template/CertificateTemplate'

const loading = (
  <>
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  </>
)

function AuthenticatedRoutes() {
  const { isAuthenticated } = useAuthState()
  const FormBuilder = React.lazy(() => import('./views/forms/FormAdd'))
  const Register = React.lazy(() => import('./views/pages/register/Register'))
  const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
  const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

  if (!isAuthenticated) {
    return null
  }

  return (
    <ImporterProvider>
      <CreditsProvider>
        <Suspense fallback={loading}>
          <Routes>
            {/* Routes inside /app */}
            <Route exact path="/register" element={<Register />} />
            <Route path="/admin/forms/add/:formId" element={<FormBuilder />} />
            <Route path="/*" element={<DefaultLayoutRoutes />} />
          </Routes>
        </Suspense>
        <LoginHandler />
      </CreditsProvider>
    </ImporterProvider>
  )
}

function DefaultLayoutRoutes() {
  const CertificateTemplate = React.lazy(() =>
    import('./views/consignments/contents/generate/template/CertificateTemplate'),
  )
  const Register = React.lazy(() => import('./views/pages/register/Register'))
  const FormBuilder = React.lazy(() => import('./views/forms/FormAdd'))
  const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
  const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

  return (
    <DefaultLayout>
      <Routes>
        <Route path="/app/register" element={<Register />} />
        <Route path="/app/admin/forms/add/:formId" element={<FormBuilder />} />
        <Route path="/app/CertificateTemplate" element={<CertificateTemplate />} />
        <Route path="/app/404" element={<Page404 />} />
        <Route path="/app/500" element={<Page500 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </DefaultLayout>
  )
}

function UnauthenticatedRoutes() {
  const Landing = React.lazy(() => import('./views/landing/Landing'))
  const GetStarted = React.lazy(() => import('./views/landing/GetStarted'))

  return (
    // <UnauthenticatedLayout>
    <Suspense fallback={loading}>
      <Routes>
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="*" element={<Landing />} />
      </Routes>
    </Suspense>
    // </UnauthenticatedLayout>
  )
}

class App extends Component {
  render() {
    const { pca } = this.props
    slk(process.env.REACT_APP_SURVEYJS_KEY)
    return (
      <MsalProvider instance={pca}>
        <Routes>
          <Route path="/app/*" element={<AuthenticatedRoutes />} />
          <Route path="/*" element={<UnauthenticatedRoutes />} />
        </Routes>
      </MsalProvider>
    )
  }
}

App.propTypes = {
  pca: PropTypes.object.isRequired,
}

export default App
