import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

//import Language files
import enTranslation from './translations/en.json'
import frTranslation from './translations/fr.json'
import bgTranslation from './translations/bg.json'
import esTranslation from './translations/es.json'
import csTranslation from './translations/cs.json'
import daTranslation from './translations/da.json'
import deTranslation from './translations/de.json'
import etTranslation from './translations/et.json'
import elTranslation from './translations/el.json'
import hrTranslation from './translations/hr.json'
import itTranslation from './translations/it.json'
import lvTranslation from './translations/lv.json'
import ltTranslation from './translations/lt.json'
import huTranslation from './translations/hu.json'
import mtTranslation from './translations/mt.json'
import nlTranslation from './translations/nl.json'
import plTranslation from './translations/pl.json'
import ptTranslation from './translations/pt.json'
import slTranslation from './translations/sl.json'
import roTranslation from './translations/ro.json'
import fiTranslation from './translations/fi.json'
import svTranslation from './translations/sv.json'

//Initialize i18n
i18n.use(initReactI18next).init({
  resources: {
    //Add language files
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    bg: {
      translation: bgTranslation,
    },
    es: {
      translation: esTranslation,
    },
    cs: {
      translation: csTranslation,
    },
    da: {
      translation: daTranslation,
    },
    de: {
      translation: deTranslation,
    },
    et: {
      translation: etTranslation,
    },
    el: {
      translation: elTranslation,
    },
    hr: {
      translation: hrTranslation,
    },
    it: {
      translation: itTranslation,
    },
    lv: {
      translation: lvTranslation,
    },
    lt: {
      translation: ltTranslation,
    },
    hu: {
      translation: huTranslation,
    },
    mt: {
      translation: mtTranslation,
    },
    nl: {
      translation: nlTranslation,
    },
    pl: {
      translation: plTranslation,
    },
    pt: {
      translation: ptTranslation,
    },
    sl: {
      translation: slTranslation,
    },
    ro: {
      translation: roTranslation,
    },
    fi: {
      translation: fiTranslation,
    },
    sv: {
      translation: svTranslation,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
