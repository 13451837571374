import { useContext } from 'react'
import axios from 'axios'
import { bearerToken } from '../components/auth/authorisation'

const useApiService = () => {
  const getApiDataWithParams = async (url, params, tenantId) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: tenantId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.post(url, params)
  }

  const getApiData = async (url, tenantId) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: tenantId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.get(url)
  }

  const postApiData = async (url, data, tenantId) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: tenantId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.post(url, data)
  }

  const putApiData = async (url, data, tenantId) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: tenantId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.put(url, data)
  }

  const patchApiData = async (url, data, tenantId) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: tenantId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.patch(url, data)
  }

  const deleteApiData = async (id, tenantId) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: tenantId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.delete(id)
  }

  return {
    getApiDataWithParams,
    getApiData,
    postApiData,
    putApiData,
    patchApiData,
    deleteApiData,
  }
}

export default useApiService
