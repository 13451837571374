import React, { useEffect, useState } from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import useUserService from './services/UserService'
import { useImporter } from './contexts/ImporterContext'
import { Navigate } from 'react-router-dom'

const LoginHandler = () => {
  const { isRegistered } = useImporter() // Always call the hook
  const { process } = useUserService() // Always call the hook

  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    if (!isRegistered) {
      setShouldRedirect(true)
    }
  }, [isRegistered])

  if (shouldRedirect) {
    return <Navigate replace to="/app/register" />
  }

  return null // This component doesn't render anything
}

export default LoginHandler
