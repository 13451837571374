//Roles - all roles available for user
export const roles = {
  importer: 'importer',
  importeraccounts: 'importer-accounts',
  manufacturer: 'manufacturer',
  admin: 'admin',
}

//Scopes - permission levels available in each section
export const scopes = {
  admin: {
    create: 'admin.create',
    read: 'admin.read',
  },
  products: {
    create: 'products.create',
    read: 'products.read',
    update: 'products.update',
    createbulk: 'products.createbulk',
  },
  manufacturers: {
    create: 'manufacturers.create',
    read: 'manufacturers.read',
    update: 'manufacturers.update',
    createbulk: 'manufacturers.createbulk',
  },
  sites: {
    create: 'sites.create',
    read: 'sites.read',
    update: 'sites.update',
  },
  platforms: {
    create: 'platforms.create',
    read: 'platforms.read',
    update: 'platforms.update',
  },
  productdetail: {
    create: 'productdetail.create',
    read: 'productdetail.read',
    update: 'productdetail.update',
  },
  consignments: {
    create: 'consignments.create',
    read: 'consignments.read',
    update: 'consignments.update',
  },
  certificates: {
    create: 'certificates.create',
    read: 'certificates.read',
    update: 'certificates.update',
  },
  attestation: {
    create: 'attestation.create',
    read: 'attestation.read',
  },
  credits: {
    purchase: 'credits.purchase',
    spend: 'credits.spend',
  },
  users: {
    read: 'users.read',
    invite: 'users.invite',
  },
}

//Permissions for each role / section
export const permissions = {
  [roles.admin]: [
    scopes.admin.read,
    scopes.admin.create,
    scopes.products.read,
    scopes.products.create,
    scopes.products.update,
    scopes.products.createbulk,
    scopes.productdetail.read,
    scopes.productdetail.create,
    scopes.productdetail.update,
    scopes.manufacturers.read,
    scopes.manufacturers.create,
    scopes.manufacturers.update,
    scopes.sites.read,
    scopes.sites.create,
    scopes.sites.update,
    scopes.platforms.read,
    scopes.platforms.create,
    scopes.platforms.update,
    scopes.consignments.read,
    scopes.consignments.create,
    scopes.consignments.update,
    scopes.certificates.read,
    scopes.certificates.create,
    scopes.certificates.update,
    scopes.attestation.read,
    scopes.attestation.create,
    scopes.credits.purchase,
    scopes.credits.spend,
    scopes.users.read,
    scopes.users.invite,
  ],
  [roles.importer]: [
    scopes.products.read,
    scopes.products.create,
    scopes.products.update,
    scopes.products.createbulk,
    scopes.productdetail.read,
    scopes.productdetail.create,
    scopes.productdetail.update,
    scopes.manufacturers.read,
    scopes.manufacturers.create,
    scopes.manufacturers.update,
    scopes.manufacturers.createbulk,
    scopes.sites.read,
    scopes.sites.create,
    scopes.sites.update,
    scopes.platforms.read,
    scopes.platforms.create,
    scopes.platforms.update,
    scopes.consignments.read,
    scopes.consignments.create,
    scopes.consignments.update,
    scopes.certificates.read,
    scopes.certificates.create,
    scopes.certificates.update,
    scopes.attestation.read,
    scopes.attestation.create,
    scopes.credits.purchase,
    scopes.credits.spend,
    scopes.users.read,
    scopes.users.invite,
  ],
  [roles.importeraccounts]: [scopes.credits.purchase, scopes.credits.spend, scopes.admin.read],
  [roles.manufacturer]: [
    scopes.products.read,
    scopes.productdetail.read,
    scopes.productdetail.create,
    scopes.productdetail.update,
    scopes.sites.read,
    scopes.sites.create,
    scopes.sites.update,
    scopes.certificates.read,
    scopes.attestation.read,
    scopes.attestation.create,
    scopes.credits.spend,
    scopes.users.read,
    scopes.users.invite,
  ],
}
