import React from 'react'
import { CButton, CCol, CContainer, CRow } from '@coreui/react'

const PageNoCredits = () => {
  return (
    <div className="d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="clearfix pt-5">
              <h2 className="display-4 me-4">No Credits</h2>
              <h4 className="pt-3">You have insufficient credits to access this page.</h4>
              <p>Top up your credits using the link below.</p>
              <CButton size="lg" href="/app/credits" color="primary" className="my-4">
                Buy credits
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default PageNoCredits
