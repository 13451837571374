import React from 'react'
import { useCredits } from 'src/contexts/CreditsContext'
import { CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'

import PageNoCredits from 'src/views/pages/pageNoCredits/PageNoCredits'

const CreditCheck = ({ route }) => {
  const { requiresCredits } = useCredits()

  return (
    <>
      {requiresCredits === true ? (
        <PageNoCredits />
      ) : requiresCredits === false ? (
        <route.element />
      ) : (
        <CSpinner color="primary" />
      )}
    </>
  )
}
export default CreditCheck

CreditCheck.propTypes = {
  route: PropTypes.object.isRequired,
}
