import React from 'react'
import { useMsal } from '@azure/msal-react'
import { CButton } from '@coreui/react'

export const SignOutButton = () => {
  const { instance } = useMsal()
  const handleLogout = () => {
    instance.logoutRedirect()
  }

  return (
    <CButton
      size="sm"
      variant="outline"
      className="btnLogout"
      onClick={() => handleLogout()}
      key="logoutRedirect"
    >
      Logout
    </CButton>
  )
}
