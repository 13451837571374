// src/hooks/useAuthState.js
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { useEffect, useState } from 'react'

export const useAuthState = () => {
  const { accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [isRegistered, setIsRegistered] = useState(false) // Replace with your actual registration check logic

  useEffect(() => {
    if (isAuthenticated) {
      // Fetch additional user data or perform other necessary actions
      // e.g., check if the user is registered
      setIsRegistered(true) // Replace with actual registration check
    }
  }, [isAuthenticated])

  return { isAuthenticated, isRegistered, accounts }
}
