import { loginRequest, msalConfig } from './authConfig'
import { msalInstance } from '../../index'

export async function getTenantName() {
  const user = await getTokenClaims()
  return user.defaultImporterId
}

export function getTokenClaims() {
  const account = msalInstance.getAllAccounts()[0]
  return account?.idTokenClaims
}

export async function getUserInfo() {
  const user = await getTokenClaims()
  if (user === null) {
    return null
  }
  var claims = {
    id: user.sub,
    fullName: user.given_name + ' ' + user.family_name,
    firstName: user.given_name,
    lastName: user.family_name,
    jobTitle: user.job_title,
    companyName: user.companyName,
    addressline1: user.streetAddress,
    address: user.streetAddress + ', ' + user.city + ', ' + user.postalCode,
    city: user.city,
    postcode: user.postalCode,
    email: user.signInName,
    appTenants: user.appTenants,
    tenantName: user.tenantName,
    defaultImporterId: user.defaultImporterId,
    defaultImporterId2: user.DefaultImporterId,
    manufacturerId: user.relatedManufacturerIds,
    importerIds: user.importerIds,
    exp: user.exp,
  }
  return claims
}

export async function getUserTenantMemberships() {
  const user = await getTokenClaims()
  return user.appTenants
}

export function getUserRoles() {
  const user = getTokenClaims()
  return user.roles
}

export async function getAndCheckForUserRole(roleName) {
  const roles = await getUserRoles()
  var res = isUserTypeInRoles(roles, roleName)
  return res
}

export async function isInUserRole(roleName) {
  const roles = await getUserRoles()
  const found = roles.find((x) => x == roleName)
  if (found != undefined && found != null) {
    return true
  }
  return false
}

Object.flatten = function (data) {
  var result = {}
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']')
      if (l == 0) result[prop] = []
    } else {
      var isEmpty = true
      for (var p in cur) {
        isEmpty = false
        recurse(cur[p], prop ? prop + '.' + p : p)
      }
      if (isEmpty && prop) result[prop] = {}
    }
  }
  recurse(data, '')
  return result
}

function isUserTypeInRoles(roles, roleName) {
  if (typeof roles === 'undefined' || roles === null || roles.length === 0) {
    return false
  }
  const matchFound = roles.find((item) => {
    return item === roleName
  })
  if (matchFound != undefined) {
    console.info("Found role '" + roleName)
  }
  return matchFound
}

export async function isAdmin() {
  const roles = await getUserRoles()
  return isUserTypeInRoles(roles, 'admin')
}
export async function isImporter() {
  const roles = await getUserRoles()
  return isUserTypeInRoles(roles, 'importer')
}
export async function isImporterAccounts() {
  const roles = await getUserRoles()
  return isUserTypeInRoles(roles, 'importer-accounts')
}
export async function isManufacturer() {
  const roles = await getUserRoles()
  return isUserTypeInRoles(roles, 'manufacturer')
}
//Only used for admin currently
export async function bearerToken() {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    console.info(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.',
    )
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.',
    )
  }
  const response = await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: account,
    })
    .catch(function (error) {
      if (
        // error.errorCode === 'silent_prompt_value_error' ||
        error.errorCode === 'consent_required' ||
        error.errorCode === 'interaction_required' ||
        error.errorCode === 'login_required'
      ) {
        msalInstance
          .acquireTokenRedirect(loginRequest)
          .then(function (response) {
            return response.accessToken
          })
          .catch(function (error) {
            console.log(error)
            msalInstance.loginRedirect()
          })
      }
    })
  return response?.accessToken
}
