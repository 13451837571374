// contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react'
import { EventType } from '@azure/msal-browser'
import propTypes from 'prop-types'
import { getUserInfo } from '../components/auth/authorisation'
import useUserService from '../services/UserService'

const AuthContext = createContext()

export const AuthProvider = ({ children, msalInstance }) => {
  const [account, setAccount] = useState(null)
  const { process } = useUserService()

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback(async (event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        getUserInfo().then(async (userInfo) => {
          //alert('set 2 localStorage userInfo' + userInfo.defaultImporterId)
          localStorage.setItem('selectedImporterId', userInfo.defaultImporterId)
          process()
        })
        const account = event.payload.account
        msalInstance.setActiveAccount(account)
        setAccount(account)
      }
    })

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId)
      }
    }
  }, [msalInstance])

  return <AuthContext.Provider value={{ account }}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)

AuthProvider.propTypes = {
  children: propTypes.node,
  msalInstance: propTypes.object,
}
