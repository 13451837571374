import React, { createContext, useContext, useState, useEffect } from 'react'
import useImporterService from '../services/ImporterService'
import propTypes from 'prop-types'
import { getUserInfo } from '../components/auth/authorisation'
import { Navigate } from 'react-router-dom'

// Create the ImporterContext
export const ImporterContext = createContext()

// Export the Provider
export const ImporterProvider = ({ children }) => {
  const { getByTenantId, getByTenantIds } = useImporterService()
  const [state, setState] = useState({
    importerIds: [],
    selectedImporterId: localStorage.getItem('selectedImporterId') || null,
    defaultImporterId: null,
    importer: null,
    importers: [],
    isRegistered: true,
    isLoading: true,
    setSelectedImporterId: (selectedImporterId) => {
      localStorage.setItem('selectedImporterId', selectedImporterId)
      setState((prevState) => ({ ...prevState, selectedImporterId }))
      window.location.reload()
    },
  })

  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    const fetchImporterData = async () => {
      try {
        const userInfo = await getUserInfo()
        let importersData = []

        if (Array.isArray(userInfo.importerIds) && userInfo.importerIds.length > 0) {
          importersData = await getByTenantIds(
            [...userInfo.importerIds],
            userInfo.defaultImporterId,
          )
        }

        // if (!importersData?.data?.length > 0) {
        //   setState((prevState) => ({ ...prevState, isLoading: false }))
        //   //setShouldRedirect(true)
        //   //return
        // }

        let selectedImporterId = localStorage.getItem('selectedImporterId')
        if (!selectedImporterId) {
          selectedImporterId = userInfo.defaultImporterId
        }
        const importerData = await getByTenantId(selectedImporterId, selectedImporterId)
        const isRegistered = !!importerData.data

        // if (!isRegistered) {
        //   setShouldRedirect(true) // Trigger the redirect
        // }

        setState((prevState) => ({
          ...prevState,
          importerIds: userInfo.importerIds,
          defaultImporterId: userInfo.defaultImporterId,
          selectedImporterId: selectedImporterId,
          isRegistered: isRegistered,
          importers: importersData.data,
          importer: importerData.data,
          isLoading: false,
        }))
      } catch (error) {
        setState((prevState) => ({ ...prevState, isLoading: false }))
      }
    }

    fetchImporterData()
  }, [])

  return <ImporterContext.Provider value={{ ...state }}>{children}</ImporterContext.Provider>
}

ImporterProvider.propTypes = {
  children: propTypes.node,
}

// Export the useImporter hook
export const useImporter = () => {
  const context = useContext(ImporterContext)
  if (!context) {
    throw new Error('useImporter must be used within an ImporterProvider')
  }
  return context
}
