// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add 'isEdge' to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

export const b2cPolicies = {
  names: {
    signUp: process.env.REACT_APP_B2C_SIGNUP_POLICY,
    signUpSignIn: process.env.REACT_APP_B2C_LOGIN_POLICY,
    editProfile: process.env.REACT_APP_B2C_EDIT_PROFILE_POLICY,
  },
  authorities: {
    signUp: {
      authority: process.env.REACT_APP_B2C_SIGNUP_AUTHORITY,
    },
    signUpSignIn: {
      authority: process.env.REACT_APP_B2C_LOGIN_AUTHORITY,
    },
    editProfile: {
      authority: process.env.REACT_APP_B2C_EDIT_PROFILE_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_B2C_AUTH_DOMAIN,
}

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    authorityRegister: b2cPolicies.authorities.signUp.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/app/dashboard',
    navigateToLoginRequestUrl: false,
    validateAuthority: false,
    postLogoutRedirectUri: '/',
    extraQueryParameters: {
      tenant: process.env.REACT_APP_B2C_TENANT_NAME,
    },
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    //allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message)
        //     return
        //   case LogLevel.Info:
        //     console.info(message)
        //     return
        //   case LogLevel.Verbose:
        //     console.debug(message)
        //     return
        //   case LogLevel.Warning:
        //     console.warn(message)
        //     return
        //   default:
        //     return
        // }
      },
    },
  },
}

// Scopes you add here will be prompted for consent during login process.env.REACT_APP_B2C_SCOPES,
export const loginRequest = {
  scopes: [process.env.REACT_APP_B2C_SCOPE_1, 'openid', 'profile'],
  extraQueryParameters: {
    tenant: process.env.REACT_APP_B2C_TENANT_NAME,
  },
  // forceRefresh: true,
  // prompt: 'login',
}
