import useSecuredApiService from './useSecuredApiService'

const useUserService = () => {
  const { getApiData, getApiDataWithParams, postApiData } = useSecuredApiService()

  const process = async () => {
    return await getApiData('/user/process')
  }
  const getPaged = async (params) => {
    return await getApiDataWithParams('/user/getPaged', params)
  }
  return {
    process,
    getPaged,
  }
}
export default useUserService
