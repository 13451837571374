import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CImage, CLink, CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBarReact from 'simplebar-react'
import 'simplebar/dist/simplebar.css'

// sidebar nav config
import navigation from 'src/_nav'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const logo = useSelector((state) => state.logo)

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CLink href="/">
          <CImage className="sidebar-brand-full" src={logo} height={80} />
        </CLink>
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={80} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={80} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBarReact className="simplebar-container">
          <AppSidebarNav items={navigation} />
        </SimpleBarReact>
      </CSidebarNav>
      {/* <CSidebarToggler className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      /> */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
