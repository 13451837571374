import { useContext } from 'react'
import axios from 'axios'
import { bearerToken } from '../components/auth/authorisation'
import { useImporter } from 'src/contexts/ImporterContext'

const useSecuredApiService = () => {
  //const { selectedImporterId } = useImporter()
  const selectedImporterId = localStorage.getItem('selectedImporterId')
  //alert('selectedImporterId' + selectedImporterId)

  const getApiDataWithParams = async (url, params) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.post(url, params)
  }

  const getApiData = async (url) => {
    //alert('getApiData' + selectedImporterId)
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.get(url)
  }

  const postApiData = async (url, data) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.post(url, data)
  }

  const putApiData = async (url, data) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.put(url, data)
  }

  const patchApiData = async (url, data) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.patch(url, data)
  }

  const deleteApiData = async (id) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return request.delete(id)
  }

  const putApiFormData = async (url, data, saveNo, callback) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    request
      .put(url, data)
      .then((response) => {
        if (response.status === 200) {
          callback(saveNo, true)
        } else {
          callback(saveNo, false)
        }
      })
      .catch((error) => {
        callback(saveNo, false)
      })
  }

  const postApiFormData = async (url, id, json, saveNo, callback) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    request
      .post(url, { id: id, title: json.title, data: JSON.stringify(json) })
      .then((response) => {
        if (response.status === 200) {
          callback(saveNo, true)
        } else {
          callback(saveNo, false)
        }
      })
      .catch((error) => {
        callback(saveNo, false)
      })
  }

  const getApiBlob = async (filename) => {
    const token = await bearerToken()
    const request = axios.create({
      baseURL: process.env.REACT_APP_API_URL_UAT,
      headers: {
        tenantId: selectedImporterId,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-Disposition': 'attachment; filename=file.pdf',
      },
    })
    return request.get(filename, { responseType: 'arraybuffer' })
  }

  return {
    getApiDataWithParams,
    getApiData,
    postApiData,
    putApiData,
    patchApiData,
    deleteApiData,
    putApiFormData,
    postApiFormData,
    getApiBlob,
  }
}

export default useSecuredApiService
