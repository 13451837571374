import React from 'react'
import { useLocation } from 'react-router-dom'

import routes from 'src/routes'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname
  const appRoutePrefix = '/app'

  const getRouteName = (pathname, routes) => {
    // Function to test if a route segment is dynamic
    const isDynamicSegment = (segment) => segment.startsWith(':')

    // Function to compare two paths
    const comparePaths = (pathSegments, routeSegments) => {
      // If the number of segments doesn't match, the paths are different
      if (pathSegments.length !== routeSegments.length) return false

      // Check each segment to see if they match or if the route segment is dynamic
      return pathSegments.every((segment, index) => {
        return isDynamicSegment(routeSegments[index]) || segment === routeSegments[index]
      })
    }

    // Find the first route that matches the pathname structure
    const currentRoute = routes.find((route) => {
      var routesWithPrefix = appRoutePrefix + route.path
      const routeSegments = routesWithPrefix.split('/').filter(Boolean) // Split and remove any empty strings
      const pathnameSegments = pathname.split('/').filter(Boolean)
      return comparePaths(pathnameSegments, routeSegments)
    })

    // Return the route name if a match is found
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`.replace('//', '/') // Ensure no double slashes
      var routeName = getRouteName(currentPathname, routes)
      // if (routeName == 'View Product') {
      //   routeName = window.localStorage.getItem('productName') ?? 'View Product'
      // }
      if (routeName) {
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length, // Simplified active assignment
        })
      }
      return currentPathname
    }, '')
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="p-0 align-items-center mb-0">
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
            key={index}
          >
            {breadcrumb.name}
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
