import React from 'react'
import { scopes } from './components/auth/permissionsMap'

const Admin = React.lazy(() => import('./views/admin/AdminDashboard'))
const UserAdmin = React.lazy(() => import('./views/admin/UserAdmin'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
//Pages

const AttestationsList = React.lazy(() => import('./views/attestations/list/AttestationsList'))
const Products = React.lazy(() => import('./views/products/list/ProductList'))
const ProductsAdd = React.lazy(() => import('./views/products/product/add/ProductAdd'))
const HealthInformationAdd = React.lazy(() =>
  import('./views/products/healthInformation/add/HealthInformationAdd'),
)
const HealthInformationComplete = React.lazy(() =>
  import('./views/products/healthInformation/add/form/HealthInformationComplete'),
)
const AttestationAdd = React.lazy(() => import('./views/products/attestation/add/AttestationAdd'))
const AttestationComplete = React.lazy(() =>
  import('./views/products/attestation/add/form/AttestationAddComplete'),
)
const Manufacturers = React.lazy(() => import('./views/manufacturers/list/ManufacturerList'))
const ManufacturerListNav = React.lazy(() =>
  import('./views/manufacturers/nav/ManufacturerListNav'),
)

const ManufacturerSidePanel = React.lazy(() =>
  import('./views/manufacturers/nav/ManufacturerSidePanel'),
)

const ConsignmentList = React.lazy(() => import('./views/consignments/list/ConsignmentList'))
const ConsignmentAdd = React.lazy(() => import('./views/consignments/add/ConsignmentAdd'))
const ConsignmentView = React.lazy(() => import('./views/consignments/view/ConsignmentView'))
const ConsignmentAddComplete = React.lazy(() =>
  import('./views/consignments/add/form/ConsignmentAddComplete'),
)
const ConsignmentListNav = React.lazy(() => import('./views/consignments/nav/ConsignmentListNav'))
const OrdersDashboard = React.lazy(() => import('./views/orders/OrdersDashboard'))
const OrdersPackages = React.lazy(() => import('./views/orders/OrdersPackages'))
const Platforms = React.lazy(() => import('./views/platforms/list/PlatformList'))
const PlatformsAdd = React.lazy(() => import('./views/platforms/add/PlatformAdd'))
const PlatformsListNav = React.lazy(() => import('./views/platforms/nav/PlatformListNav'))
const PlatformAddComplete = React.lazy(() =>
  import('./views/platforms/add/form/PlatformAddComplete'),
)
const ManufacturerAdd = React.lazy(() => import('./views/manufacturers/add/ManufacturerAdd'))
const Sites = React.lazy(() => import('./views/sites/list/SiteList'))
const SitesAdd = React.lazy(() => import('./views/sites/add/SiteAdd'))
const SiteAddComplete = React.lazy(() => import('./views/sites/add/form/SiteAddComplete'))
const SiteListNav = React.lazy(() => import('./views/sites/nav/SiteListNav'))
const SiteView = React.lazy(() => import('./views/sites/view/SiteView'))
const SiteViewNav = React.lazy(() => import('./views/sites/nav/SiteViewNav'))
const ProductListNav = React.lazy(() => import('./views/products/nav/ProductListNav'))
const Product = React.lazy(() => import('./views/products/product/ProductPage'))
const ProductAttestationsList = React.lazy(() =>
  import('./views/products/attestation/history/ProductAttestationsList'),
)
const ViewAttestationDocument = React.lazy(() =>
  import('./views/products/attestation/view/ViewAttestationDocument'),
)
const ProductDetailComplete = React.lazy(() =>
  import('./views/products/product/detail/add/form/ProductDetailAddComplete'),
)
const ProductDetailAdd = React.lazy(() =>
  import('./views/products/product/detail/add/ProductDetailAdd'),
)
const Credits = React.lazy(() => import('./views/credits/CreditPackages'))
const UserList = React.lazy(() => import('./views/users/UserList'))
const PaymentSuccess = React.lazy(() => import('./views/payment/success'))
const PaymentCancelled = React.lazy(() => import('./views/payment/cancelled'))

//Forms
const FormAdd = React.lazy(() => import('./views/forms/FormAdd'))
const FormTest = React.lazy(() => import('./components/form/FormLoader'))
const FormDashboard = React.lazy(() => import('./views/forms/FormDashboard'))
const FormDashboardNav = React.lazy(() => import('./components/form/nav/FormDashboardNav'))

const ChecksRadios = React.lazy(() => import('./views/forms/examples/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() =>
  import('./views/forms/examples/floating-labels/FloatingLabels'),
)
const FormControl = React.lazy(() => import('./views/forms/examples/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/examples/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/examples/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/examples/range/Range'))
const Select = React.lazy(() => import('./views/forms/examples/select/Select'))
const Validation = React.lazy(() => import('./views/forms/examples/validation/Validation'))

//Examples
const Charts = React.lazy(() => import('./views/examples/charts/Charts'))
const Widgets = React.lazy(() => import('./views/examples/widgets/Widgets'))

const Colors = React.lazy(() => import('./views/examples/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/examples/theme/typography/Typography'))

// Examples - Icons
const CoreUIIcons = React.lazy(() => import('./views/examples/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/examples/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/examples/icons/brands/Brands'))

// Examples - Notifications
const Alerts = React.lazy(() => import('./views/examples/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/examples/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/examples/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/examples/notifications/toasts/Toasts'))

//Side Panels
const AttestationsSidePanel = React.lazy(() =>
  import('./views/attestations/nav/AttestationSidePanel'),
)
const ConsignmentSidePanel = React.lazy(() =>
  import('./views/consignments/nav/ConsignmentSidePanel'),
)
const PlatformsSidePanel = React.lazy(() => import('./views/platforms/nav/PlatformSidePanel'))
const SiteSidePanel = React.lazy(() => import('./views/sites/nav/SiteSidePanel'))
const ProductSidePanel = React.lazy(() => import('./views/products/nav/ProductSidePanel'))
const UserSidePanel = React.lazy(() => import('./views/users/nav/UserSidePanel'))

const routes = [
  {
    path: '/product/attestation/viewdocument/:attestationId',
    name: 'View Attestation Document',
    element: ViewAttestationDocument,
    scopes: [scopes.attestation.read],
  },
  { path: '/', exact: true, name: 'Home' },
  { path: '/admin', name: 'Admin', element: Admin, scopes: [scopes.admin.read] },
  { path: '/admin/users', name: 'User admin', element: UserAdmin, scopes: [scopes.admin.read] },
  { path: '/admin/forms/add/:formId', name: 'New form', element: FormAdd },
  {
    path: '/admin/forms/dashboard',
    name: 'Forms Dashboard',
    element: FormDashboard,
    headerNav: FormDashboardNav,
  },
  {
    path: '/attestations/',
    name: 'Attestations',
    element: AttestationsList,
    scopes: [scopes.attestation.read],
    sidePanel: AttestationsSidePanel,
    // headerNav: FormDashboardNav,
  },
  {
    path: '/attestations/add/complete',
    name: 'Complete',
    element: AttestationComplete,
    scopes: [scopes.consignments.create],
  },
  { path: '/admin/forms/test', name: 'Form (Test)', element: FormTest },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/credits', name: 'Credits', element: Credits, scopes: [scopes.credits.purchase] },
  {
    path: '/orders/',
    name: 'Orders - Transactions',
    element: OrdersDashboard,
    scopes: [scopes.credits.spend],
  },
  {
    path: '/orders/packages',
    name: 'Orders - Credit packages',
    element: OrdersPackages,
    scopes: [scopes.credits.spend],
  },
  {
    path: '/payment/cancelled',
    name: 'Payment Cancelled',
    element: PaymentCancelled,
    scopes: [scopes.credits.purchase],
  },
  {
    path: '/payment/success',
    name: 'Payment Success',
    element: PaymentSuccess,
    scopes: [scopes.credits.purchase],
  },
  {
    name: 'Users',
    path: '/users',
    element: UserList,
    sidePanel: UserSidePanel,
    scopes: [scopes.users.read],
  },
  {
    path: '/products',
    name: 'Products',
    element: Products,
    scopes: [scopes.products.read],
    headerNav: ProductListNav,
    sidePanel: ProductSidePanel,
  },
  {
    path: '/products/add',
    name: 'Add a new product',
    element: ProductsAdd,
    scopes: [scopes.products.create],
  },
  {
    path: '/manufacturers',
    name: 'Manufacturers',
    element: Manufacturers,
    headerNav: ManufacturerListNav,
    sidePanel: ManufacturerSidePanel,
    scopes: [scopes.manufacturers.read],
  },
  {
    path: '/consignments',
    name: 'Consignments',
    element: ConsignmentList,
    headerNav: ConsignmentListNav,
    sidePanel: ConsignmentSidePanel,
    scopes: [scopes.consignments.read],
  },
  {
    path: '/consignments/add',
    name: 'Add a new consignment',
    element: ConsignmentAdd,
    scopes: [scopes.consignments.create],
    requiresCredits: true,
  },
  {
    path: '/consignments/view/:consignmentId',
    name: 'View consignment',
    element: ConsignmentView,
    scopes: [scopes.consignments.read],
  },
  {
    path: '/consignments/add/complete',
    name: 'Complete',
    element: ConsignmentAddComplete,
    scopes: [scopes.consignments.create],
  },
  {
    path: '/platforms',
    name: 'Platforms',
    element: Platforms,
    headerNav: PlatformsListNav,
    sidePanel: PlatformsSidePanel,
    scopes: [scopes.platforms.read],
  },
  {
    path: '/platforms/add',
    name: 'Add a new platform',
    element: PlatformsAdd,
    scopes: [scopes.platforms.create],
  },
  {
    path: '/platforms/add/:platformId',
    name: 'Edit platform',
    element: PlatformsAdd,
    scopes: [scopes.platforms.update],
  },
  {
    path: '/platforms/add/complete',
    name: 'Complete',
    element: PlatformAddComplete,
    scopes: [scopes.platforms.create],
  },
  {
    path: '/manufacturers/add/:manufacturerId',
    name: 'Edit Manufacturer',
    element: ManufacturerAdd,
    scopes: [scopes.manufacturers.create],
  },
  {
    path: '/manufacturers/add/',
    name: 'Add Manufacturer',
    element: ManufacturerAdd,
    scopes: [scopes.manufacturers.create],
  },
  {
    path: '/sites',
    name: 'Sites',
    element: Sites,
    headerNav: SiteListNav,
    sidePanel: SiteSidePanel,
    scopes: [scopes.sites.create],
  },
  {
    path: '/sites/add/:siteId',
    name: 'Edit a Site',
    element: SitesAdd,
    scopes: [scopes.sites.update],
  },
  {
    path: '/sites/add/',
    name: 'Add a Site',
    element: SitesAdd,
    scopes: [scopes.sites.create],
  },
  {
    path: '/sites/view/:siteId',
    name: 'Site',
    element: SiteView,
    headerNav: SiteViewNav,
    scopes: [scopes.sites.read],
  },
  {
    path: '/sites/add/complete',
    name: 'Complete',
    element: SiteAddComplete,
    scopes: [scopes.sites.create],
  },
  {
    path: '/products/:productId',
    name: 'View Product',
    element: Product,
    scopes: [scopes.products.read],
  },
  {
    path: '/products/:productId/detail/add/',
    name: 'Add Product Detail',
    element: ProductDetailAdd,
    scopes: [scopes.productdetail.create],
    requiresCredits: true,
  },
  {
    path: '/products/:productId/attestations',
    name: 'Attestation History',
    element: ProductAttestationsList,
    scopes: [scopes.attestation.read],
  },
  {
    path: '/productdetail/add/complete',
    name: 'Complete',
    element: ProductDetailComplete,
    scopes: [scopes.productdetail.create],
  },
  {
    path: '/products/:productId/healthinformation/add/',
    name: 'Product Health Information',
    element: HealthInformationAdd,
    scopes: [scopes.productdetail.create],
    requiresCredits: true,
  },
  {
    path: '/products/healthInformation/add/complete',
    name: 'Health Information Complete',
    element: HealthInformationComplete,
    scopes: [scopes.productdetail.create],
  },
  {
    path: '/products/:productId/attestation/add/',
    name: 'Attestation',
    element: AttestationAdd,
    scopes: [scopes.attestation.create],
    requiresCredits: true,
  },
  {
    path: '/products/attestation/add/complete',
    name: 'Attestation Complete',
    element: AttestationComplete,
    scopes: [scopes.attestation.create],
  },
  { path: '/theme', name: 'Theme', element: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', element: Colors },
  { path: '/theme/typography', name: 'Typography', element: Typography },
  { path: '/charts', name: 'Charts', element: Charts },
  { path: '/forms', name: 'Forms', element: FormControl, exact: true },
  { path: '/forms/form-control', name: 'Form Control', element: FormControl },
  { path: '/forms/select', name: 'Select', element: Select },
  { path: '/forms/checks-radios', name: 'Checks & Radios', element: ChecksRadios },
  { path: '/forms/range', name: 'Range', element: Range },
  { path: '/forms/input-group', name: 'Input Group', element: InputGroup },
  { path: '/forms/floating-labels', name: 'Floating Labels', element: FloatingLabels },
  { path: '/forms/layout', name: 'Layout', element: Layout },
  { path: '/forms/validation', name: 'Validation', element: Validation },
  { path: '/icons', exact: true, name: 'Icons', element: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', element: Flags },
  { path: '/icons/brands', name: 'Brands', element: Brands },
  { path: '/notifications', name: 'Notifications', element: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', element: Alerts },
  { path: '/notifications/badges', name: 'Badges', element: Badges },
  { path: '/notifications/modals', name: 'Modals', element: Modals },
  { path: '/notifications/toasts', name: 'Toasts', element: Toasts },
  { path: '/widgets', name: 'Widgets', element: Widgets },
]

export default routes
