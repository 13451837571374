import React from 'react'
import PropTypes from 'prop-types'

export const MultiLangText = (props) => {
  const createMarkup = (html) => ({ __html: html })

  const {
    translator,
    className,
    languageCodes = [],
    translationKey,
    translationValues = {},
    delimitterType = 'newline',
  } = props

  return (
    <>
      {/* English value */}
      <span
        className={className}
        dangerouslySetInnerHTML={createMarkup(translator(translationKey, { ...translationValues }))}
      />
      {languageCodes?.length > 0 && (
        <>
          {/* Translated values */}
          {languageCodes.map((languageCode, i) => {
            const translatedValue = translator(translationKey, {
              lng: languageCode,
              ...translationValues,
            })
            return (
              <>
                {/*//Delimitter - position to translation */}
                <Delimitter type={delimitterType} />

                {languageCode !== undefined && languageCode !== 'en' && languageCode !== '' && (
                  <span
                    key={i}
                    style={{ fontStyle: 'italic' }}
                    className={`text-muted text-italic ${className}`}
                    dangerouslySetInnerHTML={createMarkup(translatedValue)}
                  />
                )}
              </>
            )
          })}
        </>
      )}
    </>
  )
}

MultiLangText.propTypes = {
  translator: PropTypes.func.isRequired,
  className: PropTypes.string,
  engValue: PropTypes.string.isRequired, // The English value to display
  translationKey: PropTypes.string.isRequired, // The key for the translation
  translationValues: PropTypes.object, // Optional dynamic values (e.g., sealNumber)
  languageCodes: PropTypes.array, // List of language codes for translation
  delimitterType: PropTypes.string, // Delimitter type (e.g., inline, newline)
}

export const Delimitter = ({ type }) => {
  switch (type) {
    case 'inline':
      return <span>&nbsp;/&nbsp;</span>
    case 'newline':
      return <span></span>
    default:
      return null
  }
}

Delimitter.propTypes = {
  type: PropTypes.string,
}
