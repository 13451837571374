import React, { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { CHeaderNav, CContainer, CSpinner, CCol, CRow } from '@coreui/react'
import AppBreadcrumb from 'src/components/layout/AppBreadcrumb'
import PermissionsCheck from 'src/components/auth/permissionCheck'
import CreditCheck from './CreditCheck'
import routes from 'src/routes'

const AppContent = () => {
  const location = useLocation()
  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    setShowSpinner(true)
    const timer = setTimeout(() => setShowSpinner(false), 500) // Adjust as needed
    return () => clearTimeout(timer)
  }, [location.pathname])

  const RenderContent = ({ route }) => (
    <PermissionsCheck scopes={route.scopes}>
      {route.requiresCredits ? (
        <CreditCheck route={route} />
      ) : route.sidePanel ? (
        <CRow>
          <CCol md={6} lg={8} xl={9}>
            <AppContentMain route={route} />
          </CCol>
          <CCol
            md={6}
            lg={4}
            xl={3}
            className="vh-100"
            style={{ backgroundColor: 'rgb(228 242 243)' }}
          >
            <route.sidePanel />
          </CCol>
        </CRow>
      ) : (
        <AppContentMain route={route} />
      )}
    </PermissionsCheck>
  )

  RenderContent.propTypes = {
    route: PropTypes.shape({
      scopes: PropTypes.array,
      requiresCredits: PropTypes.bool,
      sidePanel: PropTypes.elementType,
      headerNav: PropTypes.elementType,
      element: PropTypes.elementType.isRequired,
    }).isRequired,
  }

  const RenderRoute = (route, idx) => (
    <Route
      key={idx}
      path={route.path}
      exact={route.exact}
      name={route.name}
      element={showSpinner ? <Loading /> : <RenderContent route={route} />}
    />
  )

  return (
    <Suspense fallback={<Loading />}>
      <CContainer fluid className="mb-0">
        <Routes>
          {routes.map((route, idx) => (route.element ? RenderRoute(route, idx) : null))}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </CContainer>
    </Suspense>
  )
}

const Loading = React.memo(() => (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
    <CSpinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </CSpinner>
  </div>
))
export const AppContentMain = ({ route }) => {
  if (!route) return null

  const HeaderNavComponent = route.headerNav
  const ElementComponent = route.element

  return (
    <CContainer fluid className="p-4 pt-2">
      <CHeaderNav className="py-3 d-flex align-items-end">
        <div className="flex-grow-1">
          <AppBreadcrumb />
        </div>
        <div className="d-flex">{HeaderNavComponent && <HeaderNavComponent />}</div>
      </CHeaderNav>
      {ElementComponent && <ElementComponent />}
    </CContainer>
  )
}

AppContentMain.propTypes = {
  route: PropTypes.shape({
    headerNav: PropTypes.elementType,
    element: PropTypes.elementType.isRequired,
  }).isRequired,
}

// Set display name for memoized components
Loading.displayName = 'Loading'

export default React.memo(AppContent)
AppContent.displayName = 'AppContent'
