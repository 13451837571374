import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CImage,
  CNavLink,
  CNavItem,
  CBadge,
  CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu, cilMoney } from '@coreui/icons'

import { AppHeaderDropdown } from 'src/components/header/index'
// import { logo } from 'src/assets/brand/logo'
import logo from 'src/assets/brand/pa_logo_narrow.png'
import AppHeaderMessagesDropdown from 'src/components/header/AppHeaderMessagesDropdown'
import AppTenantDropdown from 'src/components/header/AppTenantDropdown'
import { useCredits } from 'src/contexts/CreditsContext'
import { getUserInfo } from '../auth/authorisation'
import { useImporter } from 'src/contexts/ImporterContext'
import ToggleSwitch from '../header/ToggleSwitch'

const AppHeader = () => {
  const dispatch = useDispatch()
  const { balance, isUnlimited } = useCredits()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  // const handleImporterSelect = (selectedId) => {
  //   //alert(selectedId)
  //   //alert('curr' + sessionStorage.getItem('selectedImporterId'))
  //   localStorage.setItem('selectedImporterId', selectedId)
  //   alert('set' + selectedId)
  //   window.location.reload()
  //   //alert('new' + sessionStorage.getItem('selectedImporterId'))
  //   //localStorage.setItem('selectedImporterId', userInfo.defaultImporterId)
  // }
  return (
    <CHeader position="sticky">
      {/* {localStorage.getItem('selectedImporterId')}
      <CButton onClick={() => alert(localStorage.getItem('selectedImporterId'))}>Click me</CButton> */}
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
          <CImage src={logo} height={40} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CContainer fluid className="d-flex align-items-center ">
            <AppTenantDropdown
            // importerIds={importerIds}
            // selectedImporterId={selectedImporterId}
            // onImporterSelect={handleImporterSelect}
            />
          </CContainer>
          {/* <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink> Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink> Settings</CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink>
              {/* <CIcon icon={cilMoney} size="lg" /> */}
              <div className="d-inline ms-2">
                {isUnlimited ? 'Unlimited credits' : `Credits: ${balance}`}
              </div>
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink>
              <CIcon icon={cilBell} size="lg" />
              <CBadge shape="pill" color="danger" className="ms-2" />
              {/* <span className="badge bg-danger position-absolute translate-middle top-0 start-100 rounded-circle p-1">
                <span className="visually-hidden">5 new alerts</span>
              </span> */}
            </CNavLink>
          </CNavItem>
          {/* <CNavItem>
            <CNavLink>
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav className="ms-2">
          <AppHeaderMessagesDropdown />
        </CHeaderNav>
        <CHeaderNav className="ms-2">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider className="d-md-none" />
    </CHeader>
  )
}

export default AppHeader
