import React from 'react'
import { CTable, CTableDataCell, CTableRow } from '@coreui/react'
import { format } from 'date-fns'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import i18n from '../languages/CertificateLanguageConfig' // Import the i18n instance
import { CertificateTemplateItem } from './CertificateTemplateItem'

const EhcHeader = ({ title, children, width, note }) => (
  <CTableDataCell align="top" className={width}>
    <div className="ehc-header">
      {title} {note !== null && note != undefined && <span className="note">(ref: {note})</span>}
    </div>
    <div className="p-2">{children}</div>
  </CTableDataCell>
)

EhcHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  width: PropTypes.string,
  note: PropTypes.string,
}

const EhcTableRow = ({
  width,
  leftTitle,
  leftContent,
  leftNote,
  middleTitle,
  middleNote,
  middleContent,
  rightTitle,
  rightContent,
  rightNote = null,
}) => (
  <CTableRow>
    <EhcHeader width={width} title={leftTitle} note={leftNote}>
      {leftContent}
    </EhcHeader>
    {middleContent && middleTitle && (
      <EhcHeader width={width} title={middleTitle} note={middleNote}>
        {middleContent}
      </EhcHeader>
    )}
    <EhcHeader width={width} title={rightTitle} note={rightNote}>
      {rightContent}
    </EhcHeader>
  </CTableRow>
)
EhcTableRow.propTypes = {
  leftTitle: PropTypes.string.isRequired,
  leftNote: PropTypes.string,
  leftContent: PropTypes.string.isRequired,
  middleTitle: PropTypes.string.isRequired,
  middleNote: PropTypes.string.isRequired,
  middleContent: PropTypes.string.isRequired,
  rightTitle: PropTypes.string.isRequired,
  rightNote: PropTypes.string,
  rightContent: PropTypes.string.isRequired,
  width: PropTypes.string,
}

const ProductTable = ({ languageCodes, t, content, index }) => (
  <CTable key={index} className="ehc-table">
    <EhcTableRow
      width="w-33"
      leftTitle={
        <CertificateTemplateItem languageCodes={languageCodes} translationKey="product_cn_code" />
      }
      leftContent={content.productData.commodityCode}
      middleTitle={
        <CertificateTemplateItem
          languageCodes={languageCodes}
          translationKey="product_nature_commodity"
        />
      }
      middleContent={content.productData.ingredientTypes}
      rightTitle={
        <CertificateTemplateItem
          languageCodes={languageCodes}
          translationKey="product_manufacturing_plant"
        />
      }
      rightContent={content.productData.manufacturingPlant}
    />
    <EhcTableRow
      width="w-33"
      leftTitle={
        <CertificateTemplateItem
          languageCodes={languageCodes}
          translationKey="product_number_packages"
        />
      }
      leftContent={content.numberOfPackagesTotal}
      middleTitle={
        <CertificateTemplateItem
          languageCodes={languageCodes}
          translationKey="product_type_packaging"
        />
      }
      middleContent={content.productData.typeOfPacking}
      rightTitle={
        content.productData.weightType == 1 ? (
          <CertificateTemplateItem
            languageCodes={languageCodes}
            translationKey="product_total_weight_label_cases"
          />
        ) : (
          <CertificateTemplateItem
            languageCodes={languageCodes}
            translationKey="product_total_weight_label_units"
          />
        )
      }
      rightContent={
        <CertificateTemplateItem
          languageCodes={languageCodes}
          translationKey="product_total_weight_value"
          translationValues={{
            netWeight: content.grossWeightTotal.toString().replaceAll('kg', ''),
            grossWeight: content.grossWeightTotal.toString().replaceAll('kg', ''),
          }}
        />
      }
    />
    <EhcTableRow
      width="w-33"
      leftTitle={
        <CertificateTemplateItem
          languageCodes={languageCodes}
          translationKey="product_batch_numbers"
        />
      }
      leftContent={content.productData.batchNumbers}
      middleTitle={
        <CertificateTemplateItem
          languageCodes={languageCodes}
          translationKey="product_date_of_production"
        />
      }
      middleContent={content.dateOfProduction}
      rightTitle={
        <CertificateTemplateItem
          languageCodes={languageCodes}
          translationKey="product_final_consumer"
        />
      }
      rightContent={getCheckboxHtml(content.productData.finalConsumer)}
    />
  </CTable>
)

ProductTable.propTypes = {
  content: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  languageCodes: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const getCheckboxHtml = (isChecked) => {
  return isChecked
    ? parse("<div class='fake-checkbox-container checked'></div>")
    : parse("<div class='fake-checkbox-container'></div>")
}

const getFormattedEstablishments = (establishments) => {
  const establishmentNumbers = []
  if (establishments !== null) {
    if (establishments.includes(',')) {
      var split = establishments.split(',')
      split.map((establishment) => {
        var establishmentsSplit = establishment.split('##')
        establishmentNumbers.push(establishmentsSplit[0] + ' (' + establishmentsSplit[1] + ')')
      })
    } else {
      var establishmentsSplit = establishments.split('##')
      establishmentNumbers.push(establishmentsSplit[1])
    }
  }
  return establishmentNumbers.join(', ')
}
const CertificateTemplate = ({
  certificateData,
  signature,
  stamp,
  userName,
  importerName,
  importerFullAddress,
  languageCodes,
}) => {
  const { t } = i18n
  const firstProduct = certificateData.consignmentContents[0]?.productData
  const signatureImg = signature ? <img width="300px" src={signature} /> : null
  const stampImg = stamp ? <img height="300px" src={stamp} /> : null

  return (
    <div className="ehc">
      <h1 className="text-center text-bold p-0">
        <CertificateTemplateItem languageCodes={languageCodes} translationKey="title" />
      </h1>
      <div className="section pt-0 part-1" style={{ pageBreakAfter: 'always' }}>
        <h3 className="p-0 text-center">
          <CertificateTemplateItem
            languageCodes={languageCodes}
            translationKey="section_1_header"
          />
        </h3>
        <CTable className="ehc-table summary-table text-left w-100">
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="consignor_exporter_label"
              />
            }
            leftContent={`${certificateData.exporterName}, ${certificateData.exporterFullAddress}`}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="attestation_reference_label"
              />
            }
            rightContent={`${certificateData.reference}`}
            leftNote="1.1"
            rightNote="1.2"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="consignee_importer_label"
              />
            }
            leftContent={`${certificateData.importerName}, ${certificateData.importerFullAddress}`}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="operator_responsible_label"
              />
            }
            rightContent={`${certificateData.operatorName}, ${certificateData.operatorFullAddress}`}
            leftNote="1.5"
            rightNote="1.6"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="country_of_origin_label"
              />
            }
            leftContent={firstProduct?.countryOfOrigin}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="region_of_origin_label"
              />
            }
            rightContent={firstProduct?.regionOfOrigin}
            leftNote="1.7"
            rightNote="1.8"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="region_of_destination_label"
              />
            }
            leftContent={certificateData.destinationCountry}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="region_of_destination_label"
              />
            }
            rightContent={certificateData?.destinationRegion}
            leftNote="1.9"
            rightNote="1.10"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="place_of_dispatch_label"
              />
            }
            leftContent={`${certificateData.dispatchName}, ${certificateData.dispatchFullAddress}`}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="place_of_destination_label"
              />
            }
            rightContent={`${certificateData.destinationName}, ${certificateData.destinationFullAddress}`}
            leftNote="1.11"
            rightNote="1.12"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="place_of_loading_label"
              />
            }
            leftContent={`${certificateData.placeOfLoadingName}, ${certificateData.placeOfLoadingFullAddress}`}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="date_time_of_departure_label"
              />
            }
            rightContent={certificateData.departureDateTime}
            leftNote="1.13"
            rightNote="1.14"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="means_of_transport_label"
              />
            }
            leftContent={certificateData.transportMethod}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="border_control_post_label"
              />
            }
            rightContent={certificateData?.borderControlPost}
            leftNote="1.15"
            rightNote="1.16"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="transport_conditions_label"
              />
            }
            leftContent={
              firstProduct.temperatureType == 1 ? (
                <CertificateTemplateItem
                  languageCodes={languageCodes}
                  translationKey="transport_conditions_value_chilled"
                />
              ) : firstProduct.temperatureType == 2 ? (
                <CertificateTemplateItem
                  languageCodes={languageCodes}
                  translationKey="transport_conditions_value_ambient"
                />
              ) : (
                <CertificateTemplateItem
                  languageCodes={languageCodes}
                  translationKey="transport_conditions_value_frozen"
                />
              )
            }
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="container_seal_number_label"
              />
            }
            rightContent={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="container_seal_number_value"
                translationValues={{
                  sealNumber: certificateData.sealNumber,
                  containerNumber: certificateData.containerNumber,
                }}
              />
            }
            leftNote="1.18"
            rightNote="1.19"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="certified_for_human_consumption_label"
              />
            }
            leftContent={getCheckboxHtml(certificateData.isCertifiedHumanConsumption)}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="for_internal_market_label"
              />
            }
            rightContent={getCheckboxHtml(certificateData.isInternalMarket)}
            leftNote="1.20"
            rightNote="1.22"
          />
          <EhcTableRow
            width="w-50"
            leftTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="total_number_of_packages_label"
              />
            }
            leftContent={certificateData.totalNumberPackages}
            rightTitle={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="total_net_gross_weight_label"
              />
            }
            rightContent={
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="total_net_gross_weight_value"
                translatedValue={{
                  netWeight: certificateData.totalNetWeight?.replaceAll('kg', ''),
                  grossWeight: certificateData.totalNetWeight?.replaceAll('kg', ''),
                }}
              />
            }
            leftNote="1.24"
            rightNote="I.26"
          />
        </CTable>
      </div>
      <div className="section part-2">
        <div className="text-center">
          <h3 className="fw-bold">
            <CertificateTemplateItem
              languageCodes={languageCodes}
              translationKey="section_2_header"
            />
          </h3>
        </div>
        <div className="text-center">
          <h4 className="fw-bold">
            <CertificateTemplateItem
              languageCodes={languageCodes}
              translationKey="section_2_part_1_header"
            />
          </h4>
        </div>
        {certificateData.consignmentContents.map((content, index) => (
          <React.Fragment key={index}>
            <div className="py-2 text-start">
              <strong className="fw-bold">
                <CertificateTemplateItem languageCodes={languageCodes} translationKey="product" />
              </strong>
            </div>
            <ProductTable languageCodes={languageCodes} t={t} content={content} index={index} />
          </React.Fragment>
        ))}
      </div>
      <div className="section part-3">
        <h3 className="text-center fw-bold">
          <CertificateTemplateItem
            languageCodes={languageCodes}
            translationKey="section_3_header"
          />
        </h3>
        <div className="text-start">
          <p className="text-bold">
            <CertificateTemplateItem
              languageCodes={languageCodes}
              translationKey="declaration_text"
              translationValues={{
                userName: userName,
                importerName: importerName,
                importerFullAddress: importerFullAddress,
              }}
            />
          </p>
          <ol>
            <li>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_1"
              />
            </li>
            <li>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_2"
              />
            </li>
            <li>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_3"
              />
            </li>
            <li>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_4"
              />
              <br />
              <div className="py-2">
                <strong style={{ whiteSpace: 'pre-wrap' }}>
                  {firstProduct?.ingredientsDescription}
                </strong>
              </div>
            </li>
            <li>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_5"
              />
              <div className="py-2">
                <strong>{getFormattedEstablishments(firstProduct?.establishments)}</strong>
              </div>
            </li>
            <li>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_6"
              />
            </li>
            <li>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_7"
              />
            </li>
            <li>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_8"
              />
            </li>
            <li className={firstProduct?.containsFishery ? '' : 'strike'}>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_9"
              />
            </li>
            <li className={firstProduct?.containsDairy ? '' : 'strike'}>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_10"
              />
              <ul>
                <li className={firstProduct?.dairyTreatmentA ? 'd-block' : 'd-block strike'}>
                  <CertificateTemplateItem
                    languageCodes={languageCodes}
                    translationKey="dairy_treatment_A"
                  />
                </li>
                <li className={firstProduct?.dairyTreatmentB ? 'd-block' : 'd-block strike'}>
                  <CertificateTemplateItem
                    languageCodes={languageCodes}
                    translationKey="dairy_treatment_B"
                  />
                </li>
                <li className={firstProduct?.dairyTreatmentC ? 'd-block ' : 'd-block strike'}>
                  <CertificateTemplateItem
                    languageCodes={languageCodes}
                    translationKey="dairy_treatment_C"
                  />
                </li>
              </ul>
            </li>
            <li className={firstProduct?.containsEgg ? '' : 'strike'}>
              <CertificateTemplateItem
                languageCodes={languageCodes}
                translationKey="declaration_item_11"
              />
            </li>
          </ol>
        </div>
        <div className="section w-75" style={{ margin: 'auto' }}>
          <CTable className="pt-5 ehc-table signature-table">
            <EhcTableRow
              width="w-50"
              leftTitle={
                <CertificateTemplateItem
                  languageCodes={languageCodes}
                  translationKey="signerName"
                  delimitterType="newline"
                />
              }
              rightContent={userName}
            />
            <EhcTableRow
              width="w-50"
              leftTitle={
                <CertificateTemplateItem
                  languageCodes={languageCodes}
                  translationKey="signerImporter"
                  delimitterType="newline"
                />
              }
              rightContent={importerName}
            />
            <EhcTableRow
              width="w-50"
              leftTitle={
                <CertificateTemplateItem
                  languageCodes={languageCodes}
                  translationKey="signerSignature"
                  delimitterType="newline"
                />
              }
              rightContent={signatureImg}
            />
            <EhcTableRow
              width="w-50"
              leftTitle={
                <CertificateTemplateItem
                  languageCodes={languageCodes}
                  translationKey="signerDate"
                  delimitterType="newline"
                />
              }
              rightContent={format(new Date(), 'dd/MM/yy')}
            />
            {stampImg != undefined && (
              <EhcTableRow
                width="w-50"
                leftTitle={
                  <CertificateTemplateItem
                    languageCodes={languageCodes}
                    translationKey="signerStamp"
                    delimitterType="newline"
                  />
                }
                rightContent={stampImg}
              />
            )}
          </CTable>
        </div>
      </div>
    </div>
  )
}

CertificateTemplate.propTypes = {
  languageCodes: PropTypes.array.isRequired,
  certificateData: PropTypes.object.isRequired,
  signature: PropTypes.string.isRequired,
  stamp: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  importerName: PropTypes.string.isRequired,
  importerFullAddress: PropTypes.string.isRequired,
}

export default CertificateTemplate
