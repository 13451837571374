import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import Logo from 'src/assets/brand/pa_logo_lg.png'
import LogoDark from 'src/assets/brand/pa_logo_lg_dark.png'
const ToggleSwitch = () => {
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('darkMode') === 'true')

  useEffect(() => {
    handleDarkMode()
  }, [])

  useEffect(() => {
    handleDarkMode()
  }, [isDarkMode])

  const dispatch = useDispatch()

  const handleDarkMode = () => {
    if (isDarkMode) {
      document.documentElement.classList.add('theme-dark')
      localStorage.setItem('darkMode', true)
      dispatch({ type: 'SET_LOGO', payload: LogoDark })
    } else {
      document.documentElement.classList.remove('theme-dark')
      localStorage.setItem('darkMode', false)
      dispatch({ type: 'SET_LOGO', payload: Logo })
    }
  }

  const handleToggle = () => {
    setIsDarkMode(!isDarkMode)
  }

  return (
    <div className="toggle-switch">
      <label className="switch">
        <input type="checkbox" checked={isDarkMode} onChange={handleToggle} />
        <span className="slider round">
          <FontAwesomeIcon icon={faSun} className="toggle-off" />
          <FontAwesomeIcon icon={faMoon} className="toggle-on" />
        </span>
      </label>
    </div>
  )
}

export default ToggleSwitch
