import useSecuredApiService from './useSecuredApiService'

const useAccountService = () => {
  const { getApiData, postApiData } = useSecuredApiService()

  const getCreditInformation = () => {
    return getApiData('/credit/getCreditInformation')
  }

  return {
    getCreditInformation,
  }
}
export default useAccountService
