import React, { createContext, useContext, useState, useEffect } from 'react'
import useCreditService from '../services/CreditService'
import propTypes from 'prop-types'
import { useImporter } from './ImporterContext' // Import the ImporterContext

const CreditsContext = createContext()

export const useCredits = () => useContext(CreditsContext)

export const CreditsProvider = ({ children }) => {
  const { isLoading: isImporterLoading, isRegistered } = useImporter() // Get the loading state from ImporterProvider
  const [requiresCredits, setRequiresCredits] = useState(null)
  const [balance, setBalance] = useState(0)
  const [isUnlimited, setUnlimited] = useState(false)
  const { getCreditInformation } = useCreditService()

  useEffect(() => {
    if (!isImporterLoading && isRegistered) {
      // Only proceed if ImporterProvider is not loading
      const fetchCredits = async () => {
        try {
          const res = await getCreditInformation()
          setRequiresCredits(!res.data.hasCreditsAvailable)
          setBalance(res.data.balance)
          setUnlimited(res.data.isUnlimited)
        } catch (error) {
          // Handle error
        }
      }

      fetchCredits()
    }
  }, [isImporterLoading]) // Re-run when isImporterLoading changes

  return (
    <CreditsContext.Provider value={{ requiresCredits, balance, isUnlimited }}>
      {children}
    </CreditsContext.Provider>
  )
}

CreditsProvider.propTypes = {
  children: propTypes.node,
}
