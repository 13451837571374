import useApiService from './useApiService'

const useImporterService = () => {
  const { getApiData, getApiDataWithParams } = useApiService()

  const isTenantRegistered = (tenantId) => {
    return getApiData('/importer/IsTenantRegistered?tenantId=' + tenantId, tenantId)
  }
  const getByTenantId = (tenantId) => {
    return getApiData('/importer/GetByTenantId?tenantId=' + tenantId, tenantId)
  }
  const getByTenantIds = (tenantIds, tenantId) => {
    let importerIds = Array.isArray(tenantIds) ? tenantIds : Array.from([tenantIds])
    return getApiDataWithParams('/importer/GetByTenantIds', importerIds, tenantId)
  }
  const getImporterDetails = (tenantId) => {
    return getApiData('/importer/GetImporterDetails?tenantId=' + tenantId, tenantId)
  }

  return {
    isTenantRegistered,
    getByTenantId,
    getByTenantIds,
    getImporterDetails,
  }
}
export default useImporterService
