import { configureStore } from '@reduxjs/toolkit'

const initialState = {
  logo: '',
}

// Reducer function to handle state updates
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOGO':
      return {
        ...state,
        logo: action.payload,
      }
    // case 'SET_FOOD_BG':
    //   return {
    //     ...state,
    //     foodBg: action.payload,
    //   }
    default:
      return state
  }
}

// Create the Redux store
const store = configureStore({
  reducer: reducer,
})

export default store
