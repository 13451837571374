import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../languages/CertificateLanguageConfig' // Import the i18n instance
import { MultiLangText } from 'src/components/MultiLangText'

export const CertificateTemplateItem = (props) => {
  const { t } = i18n
  const {
    className,
    languageCodes = [],
    translationKey,
    translationValues = {},
    delimitterType = 'newline',
  } = props

  return (
    <MultiLangText
      className={className}
      translator={t}
      languageCodes={languageCodes}
      translationKey={translationKey}
      delimitterType={delimitterType}
      translationValues={translationValues}
    />
  )
}

CertificateTemplateItem.propTypes = {
  className: PropTypes.string,
  translationKey: PropTypes.string.isRequired,
  translationValues: PropTypes.object, // Optional dynamic values (e.g., sealNumber)
  languageCodes: PropTypes.array,
  delimitterType: PropTypes.string, // Delimitter type (e.g., inline, newline)
}
