import React, { useState } from 'react'
import { cilEnvelopeOpen, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CDropdown,
  CDropdownToggle,
  CNavLink,
  CDropdownMenu,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
  CListGroup,
  CListGroupItem,
  CDropdownItem,
} from '@coreui/react'
import PropTypes from 'prop-types'

const messages = [
  {
    type: 'Temprorary Outage',
    time: 'Just now',
    title: 'Consignments not currently available',
    description: 'We have an outage on Consignments. We are working to resolve this issue.',
  },
  {
    type: 'New Feature',
    time: '2 days ago',
    title: 'Released new AI tool on the platform',
    description: 'We have an outage on Consignments. We are working to resolve this issue.',
  },
]
// Shared Messages Content Component
const MessagesDropDownItem = ({ messages }) => (
  <>
    <CDropdownItem className="bg-light fw-bold py-2">You have 2 messages</CDropdownItem>
    {/* Repeat this pattern for each message */}
    {/* Message content here */}
    {messages?.length > 0 &&
      messages.map((message, i) => (
        <CDropdownItem key={i}>
          <div className="message">
            {/* <div className="pt-3 me-3 float-start">
              <div className="avatar">
                <CIcon icon={cilUser} size="xl" />
                <span className="avatar-status bg-success"></span>
              </div>
            </div> */}
            <div>
              <small className="text-medium-emphasis">{message.type}</small>
              <small className="text-medium-emphasis float-end mt-1">{message.time}</small>
            </div>
            <div className="text-truncate font-weight-bold">
              <span className="fa fa-exclamation text-danger"></span>
              {message.title}
            </div>
            <div className="small text-medium-emphasis text-truncate">{message.description}</div>
          </div>
        </CDropdownItem>
      ))}
    {/* Additional messages here */}
  </>
)
MessagesDropDownItem.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

// Shared Messages Content Component
const MessagesListMenu = ({ messages }) => (
  <>
    <CListGroupItem className="bg-light fw-semibold py-2">You have 2 messages</CListGroupItem>
    {/* Repeat this pattern for each message */}
    {messages.map((message, i) => (
      <CListGroupItem key={i}>
        <div className="message">
          <div className="pt-3 me-3 float-start">
            <div className="avatar">
              <CIcon icon={cilUser} size="xl" />
              <span className="avatar-status bg-success"></span>
            </div>
          </div>
          <div>
            <small className="text-medium-emphasis">{message.type}</small>
            <small className="text-medium-emphasis float-end mt-1">{message.time}</small>
          </div>
          <div className="text-truncate font-weight-bold">
            <span className="fa fa-exclamation text-danger"></span>
            {message.title}
          </div>
          <div className="small text-medium-emphasis text-truncate">{message.description}</div>
        </div>
      </CListGroupItem>
    ))}
  </>
)
MessagesListMenu.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

const AppHeaderMessagesDropdown = () => {
  const [visible, setVisible] = useState(false)

  return (
    <CDropdown variant="nav-item">
      {/* Dropdown Toggle for md and larger screens */}
      <CDropdownToggle placement="bottom-end" className="py-0 d-none d-md-block" caret={false}>
        <CIcon icon={cilEnvelopeOpen} size="lg" />
      </CDropdownToggle>
      {/* Dropdown Menu for md and larger screens */}
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <MessagesDropDownItem messages={messages} />
      </CDropdownMenu>
      {/* NavLink and Offcanvas for smaller screens */}
      <CNavLink className="d-md-none">
        <CIcon
          icon={cilEnvelopeOpen}
          aria-label="Toggle messages"
          aria-expanded={visible}
          size="lg"
          onClick={() => setVisible(!visible)}
        />
      </CNavLink>
      <COffcanvas
        id="offcanvasNavbar"
        placement="end"
        portal={false}
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <COffcanvasHeader>
          <COffcanvasTitle>Messages</COffcanvasTitle>
          <CCloseButton onClick={() => setVisible(false)} />
        </COffcanvasHeader>
        <COffcanvasBody>
          <CListGroup>
            <MessagesListMenu messages={messages} />
          </CListGroup>
        </COffcanvasBody>
      </COffcanvas>
    </CDropdown>
  )
}

export default AppHeaderMessagesDropdown

// const AppHeaderMessagesDropdown = () => {
//   const [visible, setVisible] = useState(false)
//   return (
//     <CDropdown variant="nav-item">
//       <CDropdownToggle placement="bottom-end" className="py-0 d-none d-md-block" caret={false}>
//         <CIcon icon={cilEnvelopeOpen} size="lg" />
//       </CDropdownToggle>
//       <CDropdownMenu className="pt-0" placement="bottom-end d-none d-md-block">
//         <CDropdownHeader className="bg-light fw-semibold py-2">You have 2 messages</CDropdownHeader>
//         <CDropdownItem>
//           <div className="message">
//             <div className="pt-3 me-3 float-start">
//               <div className="avatar">
//                 <CIcon icon={cilUser} size="xl" />
//                 <span className="avatar-status bg-success"></span>
//               </div>
//             </div>
//             <div>
//               <small className="text-medium-emphasis">Temprorary Outage</small>
//               <small className="text-medium-emphasis float-end mt-1">Just now</small>
//             </div>
//             <div className="text-truncate font-weight-bold">
//               <span className="fa fa-exclamation text-danger"></span> Consignments not currently
//               available
//             </div>
//             <div className="small text-medium-emphasis text-truncate">
//               We have an outage on Consignments. We are working to resolve this issue.
//             </div>
//           </div>
//         </CDropdownItem>
//         <CDropdownItem>
//           <div className="message">
//             <div className="pt-3 me-3 float-start">
//               <div className="avatar">
//                 <CIcon icon={cilUser} size="xl" />
//                 <span className="avatar-status bg-success"></span>
//               </div>
//             </div>
//             <div>
//               <small className="text-medium-emphasis">New Feature</small>
//               <small className="text-medium-emphasis float-end mt-1">2 days ago</small>
//             </div>
//             <div className="text-truncate font-weight-bold">
//               <span className="fa fa-exclamation text-danger"></span>
//               Released new AI tool on the platform
//             </div>
//             <div className="small text-medium-emphasis text-truncate">
//               We have an outage on Consignments. We are working to resolve this issue.
//             </div>
//           </div>
//         </CDropdownItem>
//         <CDropdownItem>
//           <div className="text-center border-top p-3">
//             <strong>View all messages</strong>
//           </div>
//         </CDropdownItem>
//       </CDropdownMenu>
//       <CNavLink className="d-md-none">
//         <CIcon
//           icon={cilEnvelopeOpen}
//           aria-label="Toggle messages"
//           aria-expanded={visible}
//           size="lg"
//           onClick={() => setVisible(!visible)}
//         />
//       </CNavLink>
//       <COffcanvas
//         id="offcanvasNavbar"
//         placement="end"
//         portal={false}
//         visible={visible}
//         onHide={() => setVisible(false)}
//       >
//         <COffcanvasHeader className="d-md-none">
//           <COffcanvasTitle>Filter menu</COffcanvasTitle>
//           <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
//         </COffcanvasHeader>
//         <COffcanvasBody>
//           <CListGroup>
//             <CListGroupItem className="bg-light fw-semibold py-2">
//               You have 2 messages
//             </CListGroupItem>
//             <CListGroupItem>
//               <div className="message">
//                 <div className="pt-3 me-3 float-start">
//                   <div className="avatar">
//                     <CIcon icon={cilUser} size="xl" />
//                     <span className="avatar-status bg-success"></span>
//                   </div>
//                 </div>
//                 <div>
//                   <small className="text-medium-emphasis">Temprorary Outage</small>
//                   <small className="text-medium-emphasis float-end mt-1">Just now</small>
//                 </div>
//                 <div className="text-truncate font-weight-bold">
//                   <span className="fa fa-exclamation text-danger"></span> Consignments not currently
//                   available
//                 </div>
//                 <div className="small text-medium-emphasis text-truncate">
//                   We have an outage on Consignments. We are working to resolve this issue.
//                 </div>
//               </div>
//             </CListGroupItem>
//             <CListGroupItem>
//               <div className="message">
//                 <div className="pt-3 me-3 float-start">
//                   <div className="avatar">
//                     <CIcon icon={cilUser} size="xl" />
//                     <span className="avatar-status bg-success"></span>
//                   </div>
//                 </div>
//                 <div>
//                   <small className="text-medium-emphasis">New Feature</small>
//                   <small className="text-medium-emphasis float-end mt-1">2 days ago</small>
//                 </div>
//                 <div className="text-truncate font-weight-bold">
//                   <span className="fa fa-exclamation text-danger"></span>
//                   Released new AI tool on the platform
//                 </div>
//                 <div className="small text-medium-emphasis text-truncate">
//                   We have an outage on Consignments. We are working to resolve this issue.
//                 </div>
//               </div>
//             </CListGroupItem>
//             <CListGroupItem>
//               <div className="text-center border-top p-3">
//                 <strong>View all messages</strong>
//               </div>
//             </CListGroupItem>
//           </CListGroup>
//         </COffcanvasBody>
//       </COffcanvas>
//     </CDropdown>
//   )
// }

// export default AppHeaderMessagesDropdown
